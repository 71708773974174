import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { Sidenav } from "components";
import theme from "assets/theme";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import brandWhite from "assets/images/logoPI.png";
import { useTranslation } from "react-i18next";
import { HTTPClientProvider, SnackbarProvider, AuthorizationChangedDialogProvider } from "helper";

// Main component of the application
export default function App() {
  const [onMouseEnter, setOnMouseEnter] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const { miniSidenav, layout, sidenavColor, transparentSidenav, whiteSidenav } = controller;
  const { t } = useTranslation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Getting all routes from src/routes.js
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AuthorizationChangedDialogProvider>
          <HTTPClientProvider>
            <CssBaseline />
            {layout === "dashboard" && (
              <Sidenav
                color={sidenavColor}
                brand={transparentSidenav || whiteSidenav ? brandWhite : brandWhite}
                brandName=""
                routes={routes({ t })}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                sx={{
                  textTransform: "none !important",
                }}
              />
            )}
            <Routes>{getRoutes(routes({ t }))}</Routes>
          </HTTPClientProvider>
        </AuthorizationChangedDialogProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
